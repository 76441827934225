export default {
  data() {
    return { languageSwitcherVisible: true };
  },
  methods: {
    hideLanguageSwitcher() {
      this.languageSwitcherVisible = false;
    },
  },
};
