import { mapState } from "vuex";

export default {
  data() {
    return {
      cookieConsentPopupShowDelay: 5000,
      showCookieConsent: false,
    };
  },
  computed: mapState(["cookieConsentGranted"]),
  watch: {
    cookieConsentGranted(granted) {
      if (!granted) this.showCookieConsentPopup();
      else this.showCookieConsent = false;
    },
  },
  mounted() {
    if (!this.cookieConsentGranted) this.showCookieConsentPopup();
  },
  methods: {
    showCookieConsentPopup() {
      setTimeout(() => {
        this.showCookieConsent = true;
      }, this.cookieConsentPopupShowDelay);
    },
  },
};
